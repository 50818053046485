
					@import './src/sass/variables';
					@import './src/sass/mixins';
				































































































































































































































































$options_line_padding: 4px 25px 6px 10px;

header::v-deep {
	margin-bottom: 0 !important;
	font-weight: inherit !important;

	li {
		margin-bottom: 0.6em;
	}
}

.block-inner::v-deep {
	.options {
		position: relative;
		margin: 0 3px;
		min-width: 150px;
		display: inline-block;
		user-select: none;
		border-radius: $border_radius;

		&:not(.freetext) {
			cursor: pointer;
			background-color: $color__white;
			padding: $options_line_padding;
			box-shadow: $box_shadow__input;

			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: 8px;
				border: 5px solid transparent;
				border-top-color: $color__dark;
				transform: translateY(-15%);
			}
		}

		&.open {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			&::after {
				transform: translateY(-70%) rotate(180deg);
			}

			.options__dropdown {
				display: block;
			}
		}

		input.options__selected {
			margin-bottom: 0;
			font-size: 1em;
		}

		.options__dropdown {
			display: none;
			position: absolute;
			overflow: hidden;
			top: 100%;
			left: 0;
			width: fit-content;
			max-width: 100vw;
			z-index: 100;
			background-color: $color__white;
			border-bottom-left-radius: $border_radius;
			border-bottom-right-radius: $border_radius;
			box-shadow: $box_shadow__input;
		}

		.options__item {
			display: block;
			padding: $options_line_padding;
			white-space: nowrap;

			&:hover {
				background-color: $color__light_blue;
				color: $color__white;
			}
		}
	}
}
